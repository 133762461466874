<template>
  <div style="margin-top: 20px">
    <p style="font-family: notosans; font-size: 16px; float: left">연락처</p>
    <div style="float: left">
      <v-btn
        v-if="iscontact === true"
        @click="updatecontact()"
        variant="outlined"
        height="26"
        class="checkbtn defaultbtn"
        style="margin-left: 20px"
      >
        완료
      </v-btn>
      <v-btn
        v-else
        @click="memomodify()"
        variant="outlined"
        height="26"
        class="editbtn defaultbtn"
        style="margin-left: 20px"
      >
        추가/수정
      </v-btn>
    </div>
  </div>
  <div style="width: 1000px">
    <table
      style="
        width: 100%;
        text-align: center;
        border-spacing: 0;
        margin-top: 55px;
      "
    >
      <tr>
        <td class="tabletd">이름</td>
        <td class="tabletd">LMS 자동 추가</td>
        <td class="tabletd">이메일 주소</td>
        <td class="tabletd">전화번호</td>
        <td class="tabletd">메모</td>
        <td class="tabletd tdlast">삭제</td>
      </tr>
      <tr v-for="(j, i) in contactlist" :key="i">
        <td class="tabletd2">
          <input
            v-model="j.name"
            spellcheck="false"
            type="text"
            style="padding: 5px; width: 55px"
            :readonly="!iscontact"
          />
        </td>
        <td class="tabletd2" style="width: 100px">{{ autoSentence(j) }}</td>
        <td class="tabletd2" style="width: 210px">
          <input
            v-model="j.mail"
            spellcheck="false"
            type="text"
            style="padding: 5px; width: 200px"
            :readonly="!iscontact"
          />
        </td>
        <td class="tabletd2" style="width: 120px">
          <input
            v-model="j.tel"
            spellcheck="false"
            type="text"
            style="padding: 5px; width: 95px"
            :readonly="!iscontact"
          />
        </td>
        <td class="tabletd2" style="width: 410px">
          <input
            v-model="j.memo"
            spellcheck="false"
            type="text"
            style="padding: 5px; width: 400px"
            :readonly="!iscontact"
          />
        </td>
        <td
          @click="deletecontact(i)"
          class="tabletd2 tdlast"
          :style="{
            fontSize: '19px',
            color: iscontact ? 'red' : 'grey',
            cursor: 'pointer',
          }"
        >
          ✖
        </td>
      </tr>
    </table>
    <v-btn
      v-show="iscontact === true"
      @click="addcontact()"
      class="contactaddbtn"
      width="100"
      variant="outlined"
      >+ 연락처 추가</v-btn
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      iscontact: false,
      extracontact: [],
    };
  },
  props: {
    contactlist_origin: {
      type: Array,
    },
    contactlist: {
      type: Array,
    },
  },
  methods: {
    autoSentence(j) {
      if (j.isLMS) {
        return "O";
      } else {
        return "-";
      }
    },
    addcontact() {
      this.contactlist.push({
        name: "",
        mail: "",
        tel: "",
        memo: "",
      });
    },
    memomodify() {
      this.iscontact = true;
    },
    deletecontact(index) {
      if (this.iscontact === false) {
        return;
      } else {
        if (this.contactlist[index]["isLMS"] === true) {
          alert("자동 추가 연락처는 삭제할 수 없습니다.");
          return;
        } else {
          this.contactlist.splice(index, 1);
        }
      }
    },
    async updatecontact() {
      const schoolCode = this.$route.params.code;
      const contactList = this.contactlist.filter(
        (item) => item.isLMS === false
      );

      for (let i = 0; i < contactList.length; i++) {
        if (!contactList[i].telCheck) {
          contactList[i].telCheck = false;
        }

        if (!contactList[i].mailCheck) {
          contactList[i].mailCheck = false;
        }
      }

      const res = await this.$store.dispatch("UPDATE_CONTACT", {
        schoolCode,
        contactList,
      });

      if (res) {
        alert("연락처 업데이트가 완료되었습니다.");
        this.iscontact = false;
      } else {
        alert("연락처 업데이트에 실패하였습니다.");
      }
    },
  },
};
</script>

<style scoped>
.defaultbtn {
  font-size: 13px;
  font-family: notosanslight;
  font-weight: bold;
  color: white;
}

.checkbtn {
  background-color: #0e7bcb;
}
.editbtn {
  background-color: #7f7f7f;
}

.disabled {
  color: grey;
}

.tabletd {
  border: 1px solid #7f7f7f;
  font-family: notosanslight;
  padding: 8px;
  font-size: 13px;
  background-color: rgb(240, 240, 240);
  border-right: none;
}

.tabletd2 {
  border: 1px solid #7f7f7f;
  font-family: notosanslight;
  font-weight: bold;
  padding: 8px;
  font-size: 14px;
  border-right: none;
  border-top: none;
}

.tdlast {
  border-right: 1px solid #7f7f7f;
}

.contactaddbtn {
  margin-top: 5px;
  float: right;
  font-size: 13px;
  border: 1px solid #7f7f7f;
  cursor: pointer;
  font-family: notosanslight;
  font-weight: bold;
  background-color: rgb(230, 230, 230);
}
</style>